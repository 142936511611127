<script lang="ts" setup>
import { vIntersectionObserver } from '@vueuse/components'
export interface Props {
  locality: string
}

const props = withDefaults(defineProps<Props>(), {
  locality: ''
})

function onIntersectionObserver([{ isIntersecting, target }]: IntersectionObserverEntry[]) {
  if (isIntersecting) {
    trackScrollToSectionIfReady(target.attributes['data-section-name'].value)
  }
}

function trackScrollToSectionIfReady(sectionName: string) {
  if (readyToTrackScrollEvents.value) {
    trackEvent('scroll_to_section', sectionName)
  }
}

const readyToTrackScrollEvents = ref(false)

onMounted(() => {
  setTimeout(() => {
    readyToTrackScrollEvents.value = true
  }, 300)
})
</script>

<template lang="pug">
section.py-24.px-3(
  v-intersection-observer="onIntersectionObserver"
  data-section-name="seo_content"
)
  .container.mx-auto
    SectionIntro.text-center.max-w-3xl
      template(#section-name) Vorteile
      template(#section-title) Vorteile im Detail

    .max-w-3xl.mx-auto.seo-content
      h3 Kein Gang zur Zulassungsstelle {{ props.locality ? `${props.locality} ` : '' }}notwendig
      p Die digitale Kfz-Abmeldung funktioniert zu 100% vollautomatisiert und digital. Sie können den Antrag über unseren Service einreichen.
      p Ihr Fahrzeug wird dann innerhalb von ca. 30 Sekunden offiziell abgemeldet und Sie erhalten eine offizielle Bescheinigung der Kfz-Abmeldung von der Zulassungsstelle {{ props.locality }}. Diese Abmeldebescheinigung erhalten Sie von uns im PDF-Format. Sie wird Ihnen auf Ihre E-Mail-Adresse zugesendet. Die Bescheinigung wird von allen Stellen anerkannt (z.B. bei Ihrer Kfz-Versicherung).
      p Ihr Vorteil: Sie sparen sich deutlich Zeit im Vergleich zum Behördengang und auch die Behörde selbst wird durch die automatisierte Bearbeitung entlastet.

      h3 Geld-zurück-Garantie
      p Sie erhalten von uns eine Geld-zurück-Garantie für 100% des von Ihnen bezahlten Betrags. Diese Garantie deckt Fälle ab, in denen die Online-Abmeldung aus irgendeinem Grund nicht funktioniert hat und Ihr Kfz deshalb noch zugelassen ist.
      p Wir stehen damit kompromisslos zu unserem Service und geben Ihnen die ultimative Sicherheit im Prozess!
      p Falls Probleme auftreten, die nicht von unserem Kundensupport in kurzer Zeit bearbeitet werden und gelöst werden können, erhalten Sie Ihr Geld automatisiert innerhalb von 12 Stunden wieder auf die von Ihnen gewählte Zahlungsmethode zurückerstattet. 

      h3 Abmeldebescheinigung in 3 Minuten
      p Wir geben uns viel Mühe, unseren Service so einfach wie möglich zu gestalten. Deshalb nehmen wir Sie im Prozess der Online-Abmeldung bestmöglich an die Hand und erklären Ihnen in jedem Schritt, was genau zu tun ist.
      p Auch ohne Vorwissen werden Sie problemlos und schnell durch den Prozess geleitet und können so Ihr Fahrzeug in nur wenigen Minuten offiziell vom Straßenverkehr abmelden.
      p Am Ende des Prozesses erhalten Sie die offizielle Abmeldebescheinigung im PDF-Format als Download und per E-Mail.

      h3 Bürokratieabbau
      p Das Digitalisierungsprojekt i-Kfz des Bundesministeriums für Digitales und Verkehr ermöglicht künftig erhebliche Kosteneinsparungen in Behörden. Gleichzeitig profitieren Bürgerinnen und Bürger von einem verbesserten Service, da sie Zulassungen und Abmeldungen bequem von Zuhause aus erledigen können. Die Digitalisierung bietet somit Vorteile auf mehreren Ebenen.

section.py-24.px-3
  .container.mx-auto
    SectionIntro.text-center.max-w-3xl
      template(#section-name) Anleitung
      template(#section-title) So funktioniert's im Detail

    .max-w-3xl.mx-auto.seo-content
      h3 Voraussetzungen
      p Falls Ihr Fahrzeug seit dem 01.01.2015 neu oder erneut zugelassen wurde, können Sie die Abmeldung bequem von zu Hause aus online durchführen. Wir erklären Ihnen, wie Sie kontrollieren können, ob Ihr Fahrzeug dafür geeignet ist.

      h4 1. Voraussetzung: Ihre Zulassungsbescheinigung Teil 1 enthält einen versteckten Sicherheitscode
      p Damit die Kfz-Abmeldung vollautomatisiert online abgewickelt werden kann, werden Zulassungsbescheinigungen mit versteckten Sicherheitscodes ausgestellt. Der Code ist 7-stellig und befindet sich hinter einer silbernen Beschichtung auf der Rückseite.
      p Kontrollieren Sie, ob Ihre Zulassungsbescheinigung Teil 1 (ehemals Fahrzeugschein) dieses Feld beinhaltet. Nur mit einem solchen Dokument können Sie das Fahrzeug digital abmelden.

      p
        a.cursor-pointer(
          v-ga-event="{ name: 'seo_content', action: 'text_link_to_zb1_code' }"
          @click="scrollToClass('sc-sicherheitscode-zb1-freilegen')"
        ) Klicken Sie hier, um genauer zu erfahren, wie man den Code freilegt

      figure
        NuxtImg(
          src="/images/code-zb1/freirubbeln.png"
          alt="Verdeckter Sicherheitscode auf der Zulassungsbescheinigung Teil 1"
          loading="lazy"
          width="768"
          style="aspect-ratio: 3/2;"
        )

        figcaption Die Zulassungsbescheinigung Teil 1 enthält einen versteckten Sicherheitscode hinter der silbernen Beschichtung auf der Rückseite

      h4 2. Voraussetzung: Ihre Kfz-Kennzeichen enthalten einen versteckten Sicherheitscode
      p Wenn Ihr Fahrzeug für die Online-Abmeldung geeignet ist, enthalten Ihre Kfz-Kennzeichen einen Sicherheitscode, der freigelegt werden muss. Jedes Kennzeichen (z.B. vorne & hinten beim Auto) enthält einen eigenen Sicherheitscode.
      p Sie können erkennen, ob Ihre Kfz-Kennzeichen einen solchen Code enthalten. Der 3-stellige Code befindet sich unter dem Bundesland-Wappen der Siegel-Plaketten. Wenn Sie auf dieser Plakette einen QR-Code sehen, können Sie Ihr Fahrzeug über unseren Service digital abmelden.

      p
        a.cursor-pointer(
          v-ga-event="{ name: 'seo_content', action: 'text_link_to_kennzeichen_code' }"
          @click="scrollToClass('sc-sicherheitscode-kennzeichen-freilegen')"
        ) Klicken Sie hier, um genauer zu erfahren, wie man den Code freilegt

      figure
        NuxtImg(
          src="/images/code-kennzeichen/wappen.png"
          alt="Verdeckter Sicherheitscode unter dem Wappen der Siegel-Plaketten der Kfz-Kennzeichen"
          loading="lazy"
          width="768"
          style="aspect-ratio: 3/2;"
        )

        figcaption Wenn die Siegel-Plakette einen QR-Code aufweist, ist Ihr Fahrzeug zur digitalen Abmeldung geeignet

      h3 Identifizierung
      p Für unseren Online-Service ist keinerlei Identifizierung notwendig. Die Kfz-Abmeldung funktioniert alleine mit den Angaben aus der Zulassungsbescheinigung Teil 1 sowie den freigelegten Sicherheitscodes.
      p Anders ist es meist bei den offiziellen Portalen der Zulassungsbehörden. Hier wird meist ein Personalausweis mit Onlinefunktion in Verbindung mit der AusweisApp2 erwartet.

      h3 Vorbereitung
      p Bevor Sie mit der Online-Abmeldung starten können, müssen Sie erst ein paar Vorbereitungen treffen.
      p Legen Sie zuerst das erforderliche Zulassungsdokument, die Zulassungsbescheinigung Teil 1 (früher Fahrzeugschein) bereit. Hier finden Sie sowohl die FIN, die für die Abmeldung gebraucht wird, als auch das Feld mit dem erforderlichen Sicherheitscode.
      p Nehmen Sie auch die Kfz-Kennzeichen von Ihrem Fahrzeug ab. Sie werden die Kennzeichen brauchen, da sich die erforderlichen Sicherheitscodes unter dem Wappen des Bundeslandes verstecken. Falls Sie einen Kennzeichenhalter haben, können Sie die Kennzeichen ganz einfach herausnehmen. Sind die Schilder festgeschraubt, müssen Sie die Schrauben entfernen, um das Kfz-Kennzeichen vom Fahrzeug zu entfernen. Die Sicherheitscodes können nur freigelegt werden, wenn die Kennzeichen auch wirklich vom Fahrzeug entfernt werden.
      p Wenn Sie die Zulassungsbescheinigung Teil 1 und die Kfz-Kennzeichen bereitgelegt haben, können Sie mit dem Prozess der Online-Abmeldung starten.

      h3 Generelle Daten: E-Mail, Kennzeichen & Telefonnummer
      p Im ersten Schritt des Prozesses müssen Sie die Kennzeichenkombination des abzumeldenden Fahrzeugs in unsere Eingabemaske eintragen. Hierüber wird zugeordnet, welches Fahrzeug abgemeldet werden soll. Geben Sie die Kennzeichenkombination so ein, wie sie in der Zulassungsbescheinigung Teil 1 steht. Falls Ihr Fahrzeug ein Saison-, Elektro- oder Oldtimerkennzeichen hat: Sie müssen die Zusätze (E, H, oder Saisonzeitraum) nicht angeben.
      p Wir benötigen auch Ihre E-Mail-Adresse und Ihre Telefonnummer. Die E-Mail wird benutzt, um Ihnen später die Abmeldebescheinigung als PDF zuzuschicken. Die Telefonnummer wird lediglich dazu benutzt, Sie zu kontaktieren, falls es Probleme mit Ihrem Antrag geben sollte.

      h3 FIN
      p Als Nächstes müssen Sie die FIN des Fahrzeugs in die Eingabemaske eintragen.
      p Die FIN, auch bekannt als Fahrzeugidentifikationsnummer oder Fahrzeug-Identifizierungsnummer, ist eine eindeutige Seriennummer, die zur Identifizierung individueller Kraftfahrzeuge, Anhänger, Motorräder und Mopeds verwendet wird. Die FIN ist eine Folge von Ziffern und Buchstaben und enthält spezifische Informationen über das Fahrzeug, wie den Hersteller, das Herstellungsjahr und den Fahrzeugtyp.
      p Sie finden die FIN in Ihrer Zulassungsbescheinigung Teil 1 im Feld E.

      figure
        NuxtImg(
          src="/images/fin-auf-zb1-markiert.png"
          alt="Bildausschnitt einer Zulassungsbescheinigung Teil 1 mit markierter FIN"
          loading="lazy"
          width="768"
          style="aspect-ratio: 3/2;"
        )

        figcaption Ihre FIN finden Sie in der Zulassungsbescheinigung Teil 1 im Feld E

      .sc-sicherheitscode-zb1-freilegen
      h3 Sicherheitscode ZB 1 freilegen
      p Der Sicherheitscode der Zulassungsbescheinigung Teil 1 muss für die Online-Abmeldung freigelegt und in unsere Eingabemaske eingetragen werden. Das Feld mit dem Sicherheitscode befindet sich auf der Rückseite und wird durch eine silberne Schicht verdeckt.
      p <strong>Vorsicht</strong>: Auf der silbernen Schicht wird bereits eine 8-stellige Zeichenfolge angezeigt, aber das ist nicht der Sicherheitscode. Der 7-stellige Sicherheitscode wird erst durch das Freilegen hinter der silbernen Schicht sichtbar.
      p Generell gibt es hier 2 Varianten von Fahrzeugdokumenten:
      ul
        li Bei der ersten Variante muss man den Sicherheitscode durch das Abziehen der silbernen Schicht freilegen. Dies funktioniert am besten mit einer Pinzette.
        li Bei der zweiten Variante muss der Code durch vorsichtiges Abrubbeln der silbernen Schicht mit einer Münze freigelegt werden. Hier sollte man besonders aufpassen, dass man den Code nicht beschädigt, was leicht passieren kann.

      p.mt-4 Zum besseren Verständnis sollten Sie sich unser Video dazu in voller Länge anschauen. Es ist wichtig, dass hier keine Fehler gemacht werden, da die Online-Abmeldung nur mit diesem Sicherheitscode gemacht werden kann.
      p.mt-4 <strong>Der häufigste Fehler ist, dass der Sicherheitscode beim Freilegen beschädigt wird</strong>.

      figure
        iframe(
          width='760'
          height='760' 
          src='https://www.youtube.com/embed/hj81-OKUQlc?mute=1&loop=1&color=white&modestbranding=1&playsinline=1&rel=0&playlist=hj81-OKUQlc'
          title='Sicherheitscodes auf der Zulassungsbescheinigung Teil 1 freilegen | einfach erklärt'
          frameborder='0'
          allow='accelerometer; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          class="aspect-[1/1] sm:h-auto w-72 sm:w-full"
          allowfullscreen=""
          loading="lazy"
          section="seo_content"
        ).w-full.h-72.mx-auto

        figcaption In diesem Video erklären wir Ihnen ganz genau, wie das Freilegen des Sicherheitscodes auf der Zulassungsbescheinigung Teil 1 funktioniert.

      .sc-sicherheitscode-kennzeichen-freilegen
      h3 Sicherheitscodes der Kfz-Kennzeichen freilegen
      p Um die Online-Abmeldung möglich zu machen, werden von den Zulassungsbehörden schon seit 2015 Sicherheitscodes in den Siegel-Plaketten (die mit dem Wappen des Bundeslandes) versteckt. Nur mit diesen Sicherheitscodes kann man sein Fahrzeug digital abmelden.
      p Bei Fahrzeugen mit 2 Kfz-Kennzeichen werden beide Kennzeichen gebraucht, da beide einen unterschiedlichen Sicherheitscode haben.
      p Sie werden von uns erst nach dem Sicherheitscode des hinteren Kennzeichens gefragt. Falls Sie die Kennzeichen schon vom Fahrzeug entfernt haben und nicht mehr wissen, welches das hintere war: Das hintere Kennzeichen hat außer der Siegel-Plakette mit dem Bundesland-Wappen auch die farbige HU-Plakette (TÜV etc.).
      p Wie bei der Zulassungsbescheinigung Teil 1 gibt es auch hier wieder 2 Varianten, wie die Sicherheitscodes freigelegt werden können:
      ul
        li Bei beiden Varianten muss man mit einem scharfen Messer einen U-Schnitt um das Bundesland-Wappen herum durchführen.
        li Nun muss man die oberste Schicht der Folie über dem Wappen entfernen.
        li Bei der ersten Variante ist der Code auf der Unterseite der abgezogenen Folie sichtbar.
        li Bei der zweiten Variante muss man den Sicherheitscode durch das vorsichtige Abrubbeln einer Schicht über dem Wappen freilegen. Benutzen Sie dafür eine Münze und drücken Sie nicht so stark, da man den Code leicht beschädigt werden kann.

      p.mt-4 <strong>Der häufigste Fehler ist, dass der Sicherheitscode beim Freilegen beschädigt wird</strong>. Wir stellen Ihnen deshalb ein Video bereit, welches das Vorgehen im Detail erläutert. 

      figure
        iframe(
          width='760'
          height='760' 
          src='https://www.youtube.com/embed/MgTPaqiALoY?mute=1&loop=1&color=white&modestbranding=1&playsinline=1&rel=0&playlist=MgTPaqiALoY'
          title='Sicherheitscodes auf Kfz-Kennzeichen freilegen | einfach erklärt'
          frameborder='0'
          allow='accelerometer; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          class="aspect-[1/1] sm:h-auto w-72 sm:w-full"
          allowfullscreen=""
          loading="lazy"
          section="seo_content"
        ).w-full.h-72.mx-auto

        figcaption In diesem Video erklären wir Ihnen ganz genau, wie das Freilegen der Sicherheitscodes auf den Kfz-Kennzeichen funktioniert

      h3 Bezahlung
      p Um Ihre Online-Abmeldung abzuschließen, müssen Sie den fälligen Betrag über eine Onlinezahlung begleichen.
      p Die Kfz-Abmeldung über unseren Service kostet insgesamt 34,90 €. Hiermit sind alle Gebühren der Zulassungsstelle {{ props.locality ? `${props.locality} ` : '' }}und des Kraftfahrt-Bundesamtes abgegolten. Der Betrag ist also All-inclusive und Sie müssen keine unerwarteten weiteren Kosten befürchten.
      p Wir unterstützen die häufigsten Zahlungsmethoden: PayPal, Kreditkarte und Rechnungskauf (über Klarna).

      img.mt-6(
        src="/images/payment-logos.svg"
        alt="Logos der Zahlungsmethoden: PayPal, Kreditkarte, Klarna"
        loading="lazy"
      )

      h3 Bestätigung & Abmeldebescheinigung
      p Wenn Sie die Gebühren bezahlt haben, wird Ihr Antrag über die offizielle Schnittstelle des Kraftfahrt-Bundesamts zur Zulassungsstelle {{ props.locality ? `${props.locality} ` : '' }}weitergeleitet. Dort wird Ihr Antrag vollautomatisiert bearbeitet und die Abmeldebescheinigung wird ausgestellt.
      p Die Bearbeitung des Antrags dauert ca. 30 Sekunden und dann können Sie Ihre Abmeldebescheinigung im PDF-Format herunterladen. Zur Sicherheit senden wir Ihnen diese auch nochmal per E-Mail zu.
      p Die Abmeldebescheinigung ist offiziell und wird an allen Stellen gleichwertig wie eine Bescheinigung in Papierform anerkannt. Ihr Fahrzeug ist damit offiziell abgemeldet und Sie haben den Prozess erfolgreich beendet.

      h3 Hintergründe zu i-Kfz
      p Das Projekt "i-Kfz" steht für "internetbasierte Fahrzeugzulassung" und ist eine Initiative des Bundesministeriums für Digitales & Verkehr, die darauf abzielt, die Prozesse der Fahrzeugzulassung zu digitalisieren und zu vereinfachen. Durch dieses System können sowohl Fahrzeugabmeldungen als auch -zulassungen vollständig online durchgeführt werden. Seit der Einführung der Großkundenschnittstelle am 1. September 2023 können Anträge auch von kommerziellen Anbietern (wie Abmeldung.digital) elektronisch an die Plattformen der Zulassungsbehörden übermittelt werden, was den Prozess für die Nutzer erheblich beschleunigt.
      p Die Bearbeitung der Anträge erfolgt normalerweise vollautomatisiert, was bedeutet, dass viele Vorgänge ohne menschliches Eingreifen abgeschlossen werden können. Dies trägt zu einer erheblichen Zeit- und Kostenersparnis sowohl für die Fahrzeughalter als auch für die Behörden bei. In Sonderfällen, in denen spezielle Prüfungen oder Dokumentationen erforderlich sind, wird eine teilautomatisierte Bearbeitung angewendet. Dies stellt sicher, dass auch komplexere Anforderungen effizient und korrekt bearbeitet werden können.
      p Durch die Einführung von i-Kfz wird somit ein wichtiger Schritt hin zu einer modernen, effizienten und benutzerfreundlichen Verwaltung gemacht, der nicht nur die Zulassungsprozesse vereinfacht, sondern auch einen Beitrag zur Digitalisierung der öffentlichen Dienstleistungen leistet.
</template>

<style lang="sass">
@import '~/assets/css/seo-content.sass'
</style>
